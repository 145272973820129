import { Feature, Map as OLMap, View } from 'ol'
import ImageLayer from 'ol/layer/Image'
import { defaults as defaultInteractions } from 'ol/interaction'
import VectorLayer from 'ol/layer/Vector'
import { Geometry, Point } from 'ol/geom'
import VectorSource from 'ol/source/Vector'
import { ImageWMS, XYZ } from 'ol/source'
import { Fill, Stroke, Style } from 'ol/style'
import CircleStyle from 'ol/style/Circle'
import GPX from 'ol/format/GPX'
import { store } from '@flint/core'
import {
  DEFAULT_RASED_MAP_CENTER,
  DEFAULT_RASED_MAP_ZOOM,
  User_Location_LAYER,
  WMS_CLICK_INTERACTION_NAME,
  WMS_HIGHLIGHTED_FEATURE_LAYER,
} from 'shared'
import {
  extractFilename,
  getPolygonExtent,
  isSimpleProject,
  logError,
  raiseSentryError,
} from 'utils'
import { ListItemI } from 'store/layout/layout'
import { setSimpleSelectedFeature } from 'store/tasks/simpleProject.async'
import { WmsLayerObj } from 'global'
import { createWmsClickInteraction } from 'utils/map.utils'
import { setSelectedFeature } from 'store/tasks/poi.async'
import Geolocation from 'ol/Geolocation'
import TileLayer from 'ol/layer/Tile'
import { positionFeature } from 'utils/trackCurrentPosition'
import { createWmsLayer } from './createWmsLayer'
import {
  baseMapsLayers,
  baseMapsLayersGroup,
  userLocation,
} from './olMapLayers'
import {
  createHighlightedVector,
  replaceFeaturesOnVector,
} from './highlightSelected'

const style = {
  Point: new Style({
    image: new CircleStyle({
      fill: new Fill({
        color: 'rgba(255,255,0,0.4)',
      }),
      radius: 5,
      stroke: new Stroke({
        color: '#ff0',
        width: 1,
      }),
    }),
  }),
  LineString: new Style({
    stroke: new Stroke({
      color: '#0f0',
      width: 3,
    }),
  }),
  MultiLineString: new Style({
    stroke: new Stroke({
      color: '#F00',
      width: 3,
    }),
  }),
}

/**
 * RasedMap class represents a custom map that extends the OpenLayers Map class.
 */
export class RasedMap extends OLMap {
  private gpxLayers: Map<string, VectorLayer<VectorSource<Geometry>>> =
    new Map()

  /**
   * The highlighted feature layer.
   */
  private highlightedFeature: null | VectorLayer<VectorSource<Geometry>>

  private geolocation: Geolocation | null

  /**
   * Constructs a new instance of the RasedMap class.
   * @param target - The HTML element or ID of the map container.
   * @param layers - The layers to be added to the map.
   * @param center - The initial center coordinates of the map.
   * @param zoom - The initial zoom level of the map.
   */
  constructor({
    target,
    layers = baseMapsLayers,
    center = DEFAULT_RASED_MAP_CENTER,
    zoom = DEFAULT_RASED_MAP_ZOOM,
    enableTracking = false,
  }) {
    super({
      target,
      layers,
      view: new View({ center, zoom }),
      controls: [],
      interactions: defaultInteractions({ pinchRotate: false }),
    })
    this.highlightedFeature = null
    this.geolocation = null

    // Add user location layer if tracking is enabled
    if (enableTracking) {
      this.addLayer(userLocation)
    }
  }

  /**
   * Adds a WMS layer to the map.
   * @param {WmsLayerObj} wmsLayerObj - The WMS layer object containing the name and type.
   * @param {boolean} [enableClickInteractions=false] - Flag to enable click interactions for the WMS layer.
   */

  addWmsLayer(
    wmsLayerObj: WmsLayerObj,
    enableClickInteractions: boolean = false
  ) {
    if (!wmsLayerObj) return
    const existingLayer = this.getAllLayers().find(
      (layer) => layer.get('name') === wmsLayerObj.type
    )

    const source = createWmsLayer({ wmsLayerObj })

    if (existingLayer) {
      existingLayer.setSource(source)
    } else {
      const wmsLayer = new ImageLayer({ source })
      wmsLayer.set('name', wmsLayerObj.type)
      wmsLayer.set('toggleable', true)
      wmsLayer.set('id', wmsLayerObj.name)
      this.addLayer(wmsLayer)
    }

    if (enableClickInteractions) {
      this.enableWmsClickInteractions(source)
    }
  }

  /**
   * Adds multiple WMS layers to the map.
   * @param wmsLayerObj - An array of WMS layer objects.
   */
  addWmsLayers(wmsLayerObj: WmsLayerObj[]) {
    wmsLayerObj.forEach((wmsLayer) => {
      this.addWmsLayer(wmsLayer)
    })
  }

  /**
   * Toggles WMS click interactions for a specified layer by name.
   * @param {string} layerName - The name of the layer for which to toggle the click interaction.
   */
  toggleWmsClickInteraction(layerName) {
    const layer = this.getLayers()
      .getArray()
      .find((layer) => layer.get('name') === layerName) as any
    if (!layer) {
      logError(
        'MapError',
        `map error in toggle click interaction: Layer not found: ${layerName}`
      )
      return
    }

    const source = layer.getSource()
    if (!(source instanceof ImageWMS)) {
      logError(
        'MapError',
        `map error in toggle click interaction, The specified layer ${layerName} is not a WMS layer`
      )
      return
    }

    // Check if an interaction for this layer already exists
    const interaction = this.getInteractions()
      .getArray()
      .find(
        (inter) =>
          inter.get('name') === WMS_CLICK_INTERACTION_NAME &&
          inter.get('layer') === layer
      )

    if (interaction) {
      // If interaction exists, remove it
      this.removeInteraction(interaction)
    } else {
      // If no interaction, create and add it
      const newInteraction = createWmsClickInteraction(source, () =>
        this.clearHighlightedFeature()
      )
      newInteraction.set('name', WMS_CLICK_INTERACTION_NAME)
      newInteraction.set('layer', layer)
      this.addInteraction(newInteraction)
    }
  }

  /**
   * Adds a clickable WMS layer to the map with interactions.
   * @param wmsLayerObj - The WMS layer object containing the name and type.
   */
  addClickableWmsLayer(wmsLayerObj: WmsLayerObj) {
    this.addWmsLayer(wmsLayerObj, true)
  }

  /**
   * Enables WMS click interactions on the specified layer source.
   * @param layerSource - The layer source to enable click interactions for.
   */
  enableWmsClickInteractions(layerSource: ImageWMS) {
    const wmsClickInteraction = createWmsClickInteraction(layerSource, () =>
      this.clearHighlightedFeature()
    )
    this.addInteraction(wmsClickInteraction)
  }

  /**
   * Adds multiple layers to the map.
   * @param layers - An array of layers to add to the map.
   */
  addLayers(layers) {
    layers.forEach((layer) => {
      this.addLayer(layer)
    })
  }

  /**
   * Removes a layer from the map by its name.
   * @param layerName - The name of the layer to remove.
   */
  removeLayerByName(layerName: string) {
    this.getLayers().forEach((layer) => {
      if (layer.get('name') === layerName) {
        this.removeLayer(layer)
      }
    })
  }

  /**
   * Clears the highlighted feature from the map.
   */
  clearHighlightedFeature() {
    this?.getLayers().forEach((layer) => {
      if (layer.get('name') === WMS_HIGHLIGHTED_FEATURE_LAYER) {
        // casting the baselayer type to vectorlayer to be able to use the clear method
        const vectorLayer = layer as VectorLayer<VectorSource<Geometry>>
        vectorLayer?.getSource().clear()
      }
    })
  }

  /**
   * Sets the center coordinates of the map.
   * @param center - The center coordinates to set.
   */
  setCenter(center) {
    this.getView().setCenter(center)
  }

  /**
   * Sets the zoom level of the map.
   * @param zoom - The zoom level to set.
   */
  setZoom(zoom) {
    this.getView().setZoom(zoom)
  }

  /**
   * Zooms the map to the boundaries of a specified task.
   * @param task - The task with boundaries to zoom to.
   */
  zoomToTaskBoundaries(task: ListItemI) {
    if (task?.boundaries) {
      const { boundaries } = task
      const extent = getPolygonExtent(boundaries)
      this.getView().fit(extent, {
        duration: 3000,
        maxZoom: 14,
      })
    }
  }

  /**
   *
   * @param {TileLayer<XYZ>} layer layer to check if it should be removed on resetting the map
   * @returns {boolean}
   */
  shouldRemoveOnReset(layer: TileLayer<XYZ>): boolean {
    return (
      !baseMapsLayersGroup.includes(layer) &&
      layer?.get('name') !== User_Location_LAYER &&
      layer?.get('name') !== WMS_HIGHLIGHTED_FEATURE_LAYER
    )
  }

  /**
   * Resets the layers and interactions of the map.
   */
  resetLayers() {
    // reset gpx layers
    if (this.gpxLayers.size > 0) {
      for (const [, layer] of this.gpxLayers) {
        this.removeLayer(layer)
      }
    }

    // reset layers
    this.getLayers().forEach((layer: TileLayer<XYZ>) => {
      if (this.shouldRemoveOnReset(layer)) {
        this.removeLayer(layer)
      }
      // clear highlighted feature
      this.clearHighlightedFeature()
    })

    // reset interactions
    this.getInteractions().forEach((interaction) => {
      if (interaction?.get('name') === WMS_CLICK_INTERACTION_NAME) {
        this.removeInteraction(interaction)
      }
    })
  }

  /**
   * Highlights a feature on the map.
   * @param feature - The feature to highlight.
   */
  highlightFeature = (feature: Feature) => {
    if (this.highlightedFeature === null) {
      this.highlightedFeature = createHighlightedVector({
        geojsonObject: feature,
      })
      this.addLayer(this.highlightedFeature)
    } else {
      replaceFeaturesOnVector({
        vector: this.highlightedFeature,
        geojsonObject: feature,
      })
    }
  }

  /**
   * Handles the selection of a feature on the map.
   * @param selectedFeature - The selected feature.
   */
  handleSelectedFeature = async (selectedFeature) => {
    this.highlightFeature(selectedFeature)
    store?.dispatch(
      isSimpleProject ? setSimpleSelectedFeature() : setSelectedFeature()
    )
  }

  /**
   * Finds and returns a layer with the specified name.
   * @param {string} layerName - The name of the layer to find.
   * @returns {Layer} The layer with the specified name, or undefined if not found.
   */
  selectLayerByName(layerName) {
    return this.getLayers()
      .getArray()
      .find((layer) => layer.get('name') === layerName)
  }

  /**
   * Resets the WMS sources for all WMS layers to reflect changes.
   */
  resetWmsSource() {
    this.getLayers().forEach((layer) => {
      if (
        layer instanceof ImageLayer &&
        layer.getSource() instanceof ImageWMS
      ) {
        const layerName = layer.get('id')
        const newSource = createWmsLayer({ wmsLayerName: layerName })
        layer.setSource(newSource)
      }
    })
  }

  /**
   * Moves the map view to the specified GPS coordinates.
   * @param {Object} options - The options object.
   * @param {number[]} options.coordinates - The GPS coordinates to move to.
   */
  moveToGPSLocation({ coordinates }) {
    this.getView().setCenter(coordinates)
    this.getView().setZoom(20)
    positionFeature.setGeometry(new Point(coordinates))
  }

  /**
   * Starts tracking the user's location on the map.
   */
  startTracking() {
    try {
      const locationSource = userLocation.getSource()
      locationSource.clear()
      locationSource.removeFeature(positionFeature)
      locationSource.addFeature(positionFeature)

      if (!this.geolocation) {
        this.geolocation = new Geolocation({
          trackingOptions: {
            enableHighAccuracy: true,
          },
          projection: this.getView().getProjection(),
        })
        this.geolocation.setTracking(true)
        this.geolocation.once('change:position', () => {
          this.moveToGPSLocation({
            coordinates: this.geolocation.getPosition(),
          })
        })
      } else {
        this.geolocation.setTracking(true)
        this.moveToGPSLocation({
          coordinates: this.geolocation.getPosition(),
        })
      }
    } catch (err) {
      raiseSentryError(
        err,
        {},
        {
          scope: 'Rased Map',
          action: 'user trying to track his location',
        }
      )
    }
  }

  /**
   * Adds a GPX layer to the map.
   * @param {string} gpxUrl - The URL to the GPX file.
   * @param {import('ol/style/Style').default} [style] - Optional style for the GPX features.
   */
  addGpxLayer(gpxUrl: string) {
    const gpxId = extractFilename(gpxUrl)
    const gpxSource = new VectorSource({
      url: gpxUrl,
      format: new GPX(),
    })

    const gpxLayer = new VectorLayer({
      source: gpxSource,
      style(feature) {
        return style[feature.getGeometry().getType()]
      },
    })

    this.addLayer(gpxLayer)
    gpxLayer.set('name', `gpx`)
    gpxLayer.set('toggleable', true)
    gpxLayer.set('id', gpxId)
    this.gpxLayers.set(gpxId, gpxLayer)
  }

  /**
   * Shows a GPX layer.
   * @param {string} layerId - The identifier for the GPX layer.
   */
  showGpxLayer(layerId: string) {
    const layer = this.gpxLayers.get(layerId)
    if (layer) {
      layer.setVisible(true)
    }
  }

  /**
   * Hides a GPX layer.
   * @param {string} layerId - The identifier for the GPX layer.
   */
  hideGpxLayer(layerId: string) {
    const layer = this.gpxLayers.get(layerId)
    if (layer) {
      layer.setVisible(false)
    }
  }

  /**
   * Shows all GPX layers.
   */
  showAllGpxLayers() {
    this.gpxLayers.forEach((layer) => {
      layer.setVisible(true)
    })
  }

  /**
   * Hides all GPX layers.
   */
  hideAllGpxLayers() {
    this.gpxLayers.forEach((layer) => {
      layer.setVisible(false)
    })
  }

  /**
   * Removes a GPX layer from the map.
   * @param {string} layerId - The identifier for the GPX layer.
   */
  removeGpxLayer(layerId: string) {
    const layer = this.gpxLayers.get(layerId)
    if (layer) {
      this.removeLayer(layer)
      this.gpxLayers.delete(layerId)
    }
  }
}
