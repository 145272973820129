import { gql } from '@apollo/client'

export const GET_SIMPLE_PROJECT = gql`
  query GetSimpleProjects {
    projects {
      code
      created
      id
      modified
      name
    }
  }
`
export const FETCH_SIMPLE_PROJECT_TASKS = gql`
  query FetchSimpleProjectTasks(
    $projectId: Int!
    $offset: BoundedInt
    $filters: [DjangoFilterInput!]
  ) {
    rasedTasks(
      projectId: $projectId
      pageInfo: { limit: 10, offset: $offset, orderBy: "id" }
      filters: $filters
    ) {
      data {
        code
        created
        modified
        id
        gpxUrls
        order {
          dataLayer {
            id
            key
            wmsFeatureTypeName
          }
          paLayer {
            id
            key
            wmsFeatureTypeName
          }
        }
        status
        boundaries
        teamCode
      }
      count
    }
  }
`

export const FETCH_SIMPLE_TASK = gql`
  query FetchSimpleProjectTasks($projectId: Int!, $pk: Int) {
    rasedTasks(projectId: $projectId, pk: $pk) {
      data {
        code
        created
        modified
        id
        gpxUrls
        paWmsName
        pointsWmsName
        status
        boundaries
        teamCode
      }
      count
    }
  }
`
export const FETCH_SIMPLE_PROJECT_TASK_COUNT = gql`
  query FetchSimpleProjectTaskCount(
    $projectId: Int!
    $offset: BoundedInt
    $filters: [DjangoFilterInput!]
  ) {
    rasedTasks(
      projectId: $projectId
      pageInfo: { limit: 10, offset: $offset, orderBy: "id" }
      filters: $filters
    ) {
      count
    }
  }
`
export const SIMPLE_PROJECT_FORM_SCHEMA = gql`
  query GetSimpleProjectFormSchema($projectId: Int!) {
    orders(projectId: $projectId) {
      data {
        id
        jsonSchema
      }
    }
  }
`
export const GET_SIMPLE_TASK_CODES = gql`
  query FetchTaskList {
    rasedTasks {
      data {
        code
      }
    }
  }
`
export const GET_SIMPLE_RECORD = gql`
  query GetReacordData($taskId: Int!, $pk: Int) {
    records(taskId: $taskId, pk: $pk) {
      data {
        sourceProperties
        id
        data
        geometry
        mapData
      }
    }
  }
`
