import { SnackbarProps } from '@material-ui/core'
import { DEFAULT_BASEMAP_LABEL } from 'shared/constants'
import { BaseMapLabel } from 'global'

export interface ListItemI {
  id: number
  type: 'layer' | 'task' | 'simple'
  title: string
  wmsFeaturetypeName: string
  jsonSchema: any
  webUiJsonSchema: any
  userPermissions: any
  layerKey?: string
  boundaries?: any
  dataLayer?: any
  paWmsName?: any
  parcelsWmsName?: any
  pointsWmsName?: any
  baseLayer?: any
  wmsDataLayerName: string
  teamCode?: string
  paCode?: string
  paWmsId?: any
  parcelsWmsId?: any
  pointsWmsId?: any
  gpxUrls?: string[]
  targetProject?: string
}

export interface ILayoutState {
  snackbar?: SnackbarProps
  layersDrawerOpen?: boolean
  formDrawerOpen?: boolean
  projectSwitcherDrawer?: boolean
  // error drawer
  errorMessage?: string
  selectedListItem: null | ListItemI
  isSuperuser: boolean
  selectedBaseMapLabel: BaseMapLabel
  isDataLoading: boolean
  countLoading: boolean
  globalTaskCount
  userAvatar: null | string
}

export const layoutReducer = (): ILayoutState => ({
  snackbar: {},
  layersDrawerOpen: false,
  formDrawerOpen: false,
  projectSwitcherDrawer: false,
  selectedListItem: null,
  isSuperuser: false,
  selectedBaseMapLabel: DEFAULT_BASEMAP_LABEL,
  isDataLoading: false,
  countLoading: false,
  globalTaskCount: {},
  userAvatar: null,
})
