import { Dispatch } from 'redux'
import { generalGqlClient } from 'lib/graphql'
import { TASKS_COUNT, USER_AVATAR } from 'global'
import { logError, raiseSentryError } from 'utils'
import {
  setCountLoading,
  setGlobalTaskCount,
  setUserAvatar,
} from './layout.actions' // Import your Redux action creators

export const fetchGlobalTaskCount = () => {
  return async (dispatch: Dispatch) => {
    dispatch(setCountLoading(true))

    try {
      const { data } = await generalGqlClient.query({ query: TASKS_COUNT })
      if (data?.userProjectTasks && data?.userProjectTasks?.projects) {
        const { projects } = data.userProjectTasks
        const newData = projects?.reduce((acc, { project }) => {
          if (project.name === 'مشروع جلب العروض')
            return { ...acc, offers: project.field.count }
          else if (project.name === 'مشروع جلب المعالم')
            return { ...acc, poi: project.field.count }
          else if (project.name === 'مشروع VD')
            return { ...acc, vd: project.field.count }

          return acc // Use acc instead of globalTasksCount
        }, {})
        dispatch(setGlobalTaskCount(newData))
      }
    } catch (error) {
      raiseSentryError(
        error,
        {},
        {
          scope: 'project switcher',
          task: 'fetching global task count',
        }
      )
    }

    dispatch(setCountLoading(false))
  }
}

export const fetchUserAvatar = () => {
  return async (dispatch: Dispatch) => {
    try {
      const { data } = await generalGqlClient.query({ query: USER_AVATAR })
      if (data?.userDetails) {
        dispatch(setUserAvatar(data?.userDetails?.avatar))
      }
    } catch (error) {
      logError('NetworkError', error)
    }
  }
}
