import { Feature } from 'ol'
import {
  toggleMultipleFeatureModal,
  updateMultipleFeatureList,
  updateSelectedRecord,
} from 'store/layers'
import { store } from '@flint/core'
import toast from 'react-hot-toast'
import { updateSelectedFeature } from 'store/tasks'
import { toggleDataLoading } from 'store/layout'
import { WMSClick } from 'pages/Map/OnClickInteraction'
import { ImageWMS } from 'ol/source'
import { ORGANIZATION_ID, WMS_CLICK_INTERACTION_NAME } from 'shared'
import { logError } from './general.utils'

/**
 * Processes the data received from a WMS click event.
 * @param data - The data received from the WMS click event.
 */
export const processWmsClickData = (data) => {
  const foundFeatures = data?.features as Feature[]

  // if no features found, show a toast
  if (!foundFeatures?.length) {
    store?.dispatch(updateSelectedRecord(null))
    toast.error('لا يمكن العثور على سجل!', {
      duration: 1500,
    })
    throw new Error('no features found on the clicked area!')
  }

  // if more than one feature found, show a feature chooser modal so the user can choose between them
  else if (foundFeatures?.length > 1) {
    store?.dispatch(toggleMultipleFeatureModal(true))
    store?.dispatch(updateMultipleFeatureList(foundFeatures))
  }
  // if one feature found, set it as a selected feature
  else if (foundFeatures.length === 1) {
    store?.dispatch(updateSelectedFeature(foundFeatures[0]))
  }
}

/**
 * Processes the error that occurred during a WMS click event.
 * @param error - The error that occurred during the WMS click event.
 * @param cb - Callback function to remove the previous highlighted feature.
 */
export const processWmsClickError = (error, cb) => {
  logError('MapError', `error while fetching features ${error}`)
  store?.dispatch(toggleDataLoading(false))
  // callback to remove previous highlighted feature
  if (cb) cb()
}

/**
 * Creates a WMS click interaction.
 * @param layerSource - The layer source for the WMS click interaction.
 * @param clearHighlightLayer - Optional callback function to clear the highlighted layer.
 * @returns The created WMS click interaction.
 */
export const createWmsClickInteraction = (
  layerSource: ImageWMS,
  clearHighlightLayer?: () => void
) => {
  const setLoading = () => {
    store?.dispatch(toggleDataLoading(true))
  }
  const drawerOpen = store?.getState()?.layers?.drawingTools?.openFlag
  const wmsClick = new WMSClick(
    drawerOpen,
    layerSource,
    ORGANIZATION_ID,
    setLoading,
    processWmsClickData,
    (err) => processWmsClickError(err, clearHighlightLayer)
  )
  wmsClick.set('name', WMS_CLICK_INTERACTION_NAME)
  return wmsClick
}
