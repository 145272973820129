import { baseMaps } from 'pages/Map/olMapLayers'

export const DEFAULT_BASEMAP_LABEL = baseMaps[0].label

export const ORGANIZATION_ID =
  Number(process.env.REACT_APP_ORGANIZATION_ID) ?? 16

export const DEFAULT_RASED_MAP_CENTER = [4433425.4492, 2442330.9409]
export const DEFAULT_RASED_MAP_ZOOM = 14
export const WMS_CLICK_INTERACTION_NAME = 'wms_click_interaction'
export const WMS_HIGHLIGHTED_FEATURE_LAYER = 'wms_highlighted_feature_layer'
export const User_Location_LAYER = 'user_location_layer'
export const acceptableExtensions = ['jpg', 'jpeg', 'png', 'webp', 'bmp']
