import React, { Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Divider } from '@material-ui/core'
import validator from '@rjsf/validator-ajv8'
import toast from 'react-hot-toast'
import { useTranslation } from '@flint/locales'
import { ReactJsonSchemaForm } from '@flint/rjsf'
import { RootState } from 'store'
import { AddPhotoToGallery } from 'Rjsf'
import { ORGANIZATION_ID } from 'shared'
import { resetWMSLayers, updateDrawingTools } from 'store/layers'
import { toggleDataLoading, toggleFormDrawer } from 'store/layout'
import { PoiTaskService } from 'services/poi.service'
import { compressImg, isEmptyObject, logError } from 'utils/general.utils'
import { useStyle } from './RecordForm.style'

export function PoiRecordForm() {
  const { t } = useTranslation()
  const classes = useStyle()
  const dispatch = useDispatch()

  const { selectedRecord, resetWMSFlag, drawingTools } = useSelector(
    (rootState: RootState) => rootState.layers
  )

  const { isDataLoading, selectedListItem } = useSelector(
    (rootState: RootState) => rootState.layout
  )

  const { selectedRecordSchema } = useSelector(
    (rootState: RootState) => rootState.tasks
  )

  const { jsonSchema, webUiJsonSchema } =
    selectedRecordSchema || selectedListItem || {}

  const TaskServiceInstance = new PoiTaskService()
  const [formData, setFormData] = useState(selectedRecord?.data)
  const [hiddenForm, setHiddenForm] = useState(false)

  const updateExisting = () => {
    const data = {
      recordId: selectedRecord.id,
      taskId: selectedListItem.id,
      formData: JSON.stringify(formData || {}),
    }
    TaskServiceInstance.updateRecord({
      ...data,
      onSuccess: () => {
        toast.success(t('saved successfully'), {
          duration: 1500,
        })
      },
      onFail: () => {
        toast.error(t('offer not edited'), {
          duration: 1500,
        })
      },
    })
  }

  const addNewRecord = () => {
    const recordData = {
      taskId: selectedListItem.id,
      geometry: selectedRecord.wkt,
      formData: JSON.stringify(formData ?? {}),
    }

    TaskServiceInstance.createRecordTask({
      recordData,
      onSuccess: () => {
        toast.success(t('offer added successfully'), {
          duration: 1500,
        })
      },
      onFail: () => {
        toast.error(t('You have not added offer'), {
          duration: 1500,
        })
      },
    })
    dispatch(updateDrawingTools({ ...drawingTools, openFlag: false }))
  }

  const onSubmit = () => {
    dispatch(toggleDataLoading(true))
    if (selectedRecord.wkt) addNewRecord()
    else updateExisting()
    dispatch(resetWMSLayers(!resetWMSFlag))
    dispatch(toggleDataLoading(false))
    dispatch(toggleFormDrawer(false))
  }

  const forceResetRerender = () => {
    const p = new Promise((resolve) => {
      setHiddenForm(true)
      setFormData({})
      resolve(true)
    })
    p.then(() => {
      setHiddenForm(false)
    })
  }
  const onCancel = () => {
    if (isDataLoading) return
    dispatch(toggleFormDrawer(false))
    forceResetRerender()
  }

  const onError = (error: any) => {
    toast.error(t('form_error_msg'), {
      duration: 1500,
    })
    logError('LogicError', error)
  }
  const onFileChange = async (data: Array<{ file: File }> | { file: File }) => {
    let response: any
    dispatch(toggleDataLoading(true))
    try {
      if (Array.isArray(data)) {
        const promises = data.map(async ({ file }) => {
          const compressedFile = await compressImg(file)
          return TaskServiceInstance.uploadViaSignedUrl(
            compressedFile,
            selectedListItem?.id,
            ORGANIZATION_ID,
            TaskServiceInstance.uploadFile
          )
        })
        response = await Promise.all(promises)
      } else {
        const compressedFile = await compressImg(data?.file)
        response = await TaskServiceInstance.uploadViaSignedUrl(
          compressedFile,
          selectedListItem?.id,
          ORGANIZATION_ID,
          TaskServiceInstance.uploadFile
        )
      }
    } catch (error) {
      logError('LogicError', error)
      dispatch(toggleDataLoading(false))
      return
    }
    dispatch(toggleDataLoading(false))
    return response
  }

  // force reset the whole rjsf component
  useEffect(forceResetRerender, [])

  // update form data from redux store
  useEffect(() => {
    if (!selectedRecord) dispatch(toggleFormDrawer(false))
    setFormData(selectedRecord?.data)
  }, [selectedRecord?.id])

  // eslint-disable-next-line
  if (!jsonSchema || isEmptyObject(jsonSchema)) return <Fragment />

  return (
    <div className={classes.entrySection}>
      <div className={classes.entrySectionFormSchemaContainer}>
        {!hiddenForm && (
          <ReactJsonSchemaForm
            transformErrors={(errors) => {
              const modfiedErrors = errors?.map((err) => {
                if (err.name === 'required' || err.name === 'minItems') {
                  return { ...err, message: 'حقل مطلوب' }
                }
                if (err.name === 'enum') {
                  return { ...err, message: 'يرجى الإختيار من القيم الموجودة' }
                }
                return err
              })

              return modfiedErrors
            }}
            validator={validator}
            experimental_defaultFormStateBehavior={{
              allOf: 'populateDefaults',
            }}
            onSubmit={onSubmit}
            onError={onError}
            schema={jsonSchema as any}
            uiSchema={webUiJsonSchema as any}
            formData={formData}
            setFormData={setFormData}
            liveValidate={false}
            showErrorList={false}
            disabled={isDataLoading}
            formContext={{ onFileChange }}
            omitExtraData
            liveOmit
            noHtml5Validate
            widgets={{ AddPhotoToGallery }}
          >
            <Divider />
            {/* <input ref={submitButtonRef as any} type="submit" value="" hidden /> */}
            <div className={classes.actionsContainer}>
              {/* <input type="submit" hidden /> */}
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                size="small"
                fullWidth
                disableElevation
                disabled={isDataLoading}
                // onClick={onSave}
                type="submit"
              >
                {t('save')}
              </Button>
              <Button
                onClick={onCancel}
                className={classes.actionButton}
                variant="contained"
                color="default"
                size="small"
                type="reset"
                fullWidth
                disableElevation
                disabled={isDataLoading}
              >
                {t('cancel')}
              </Button>
            </div>
          </ReactJsonSchemaForm>
        )}
      </div>
    </div>
  )
}
