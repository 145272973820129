import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'store'
import { RasedMap } from 'pages/Map/RasedMap'
import { getTaskWmsLayerData } from 'utils'
import BaseMap from 'pages/Map/BaseMap'

import { LocationFab } from 'components/LocationFab'

export const PoiMap = () => {
  const mapRef = useRef<RasedMap | null>(null)

  const { selectedListItem } = useSelector((state: RootState) => state.layout)

  const { selectedFeature } = useSelector((state: RootState) => state.tasks)

  useEffect(() => {
    if (selectedFeature) {
      mapRef.current.handleSelectedFeature(selectedFeature)
    }
  }, [selectedFeature])

  useEffect(() => {
    if (selectedListItem) {
      mapRef.current.zoomToTaskBoundaries(selectedListItem)
      const taskWmsLayers = getTaskWmsLayerData(selectedListItem, 'poi')

      // add regular base wms layers
      mapRef.current.addWmsLayers(
        taskWmsLayers.filter((l) => l.type !== 'points')
      )

      // add clickable wms layer
      mapRef.current.addClickableWmsLayer(
        taskWmsLayers.find((l) => l.type === 'points')
      )
    }

    return () => {
      // reset layers
      mapRef.current.resetLayers()
    }
  }, [selectedListItem])

  const handleLocationButtonClick = () => {
    if (mapRef.current) {
      mapRef.current.startTracking()
    }
  }

  return (
    <>
      <BaseMap mapRef={mapRef} />
      <LocationFab onClick={handleLocationButtonClick} />
    </>
  )
}
