import { Image as ImageLayer } from 'ol/layer'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  toggleMultipleFeatureModal,
  updateMultipleFeatureList,
  updateSelectedRecord,
} from 'store/layers'
import {
  getPolygonExtent,
  getRecordData,
  getSelectedProject,
} from 'utils/tasks.utils'
import { toggleFormDrawer, toggleDataLoading } from 'store/layout'
import { RootState } from 'store/reducer'
import toast from 'react-hot-toast'

import { ORGANIZATION_ID } from 'shared'
import { useTranslation } from '@flint/locales'
import { updateSelectedFeature } from 'store/tasks'
import { logError } from 'utils'
import {
  createHighlightedVector,
  replaceFeaturesOnVector,
} from './highlightSelected'
import { useStyle } from './Map.style'
import { WMSClick } from './OnClickInteraction'
import { createWmsLayer } from './createWmsLayer'
import BaseMap from './BaseMap'

const selectedProject = getSelectedProject()

export const MapComponent = ({ mapRef }) => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const wmsLayerRef = useRef(null)
  const wmsDataLayerRef = useRef(null)
  const wmsInteractionRef = useRef(null)
  const highlighted = useRef(null)

  const { resetWMSFlag, drawingTools, selectedRecord } = useSelector(
    (state: RootState) => state.layers
  )
  const { selectedListItem } = useSelector((state: RootState) => state.layout)
  const { selectedFeature } = useSelector((state: RootState) => state.tasks)

  const { openFlag } = drawingTools

  const setLoading = () => {
    dispatch(toggleDataLoading(true))
  }

  const highlightFeatures = (geojsonObject) => {
    if (highlighted.current === null) {
      highlighted.current = createHighlightedVector({ geojsonObject })
      mapRef.current.addLayer(highlighted.current)
    } else
      replaceFeaturesOnVector({ vector: highlighted.current, geojsonObject })
  }

  const processWmsClickData = (data) => {
    const foundFeatures = data?.features
    // if no features found, show a toast
    if (!foundFeatures?.length) {
      dispatch(updateSelectedRecord(null))
      toast.error(t('Could not find features'), {
        duration: 1500,
      })
      throw new Error('no features found on the clicked area!')
    }
    // if more than one feature found, show a feature chooser modal so the user can choose between them
    else if (foundFeatures?.length > 1) {
      dispatch(toggleMultipleFeatureModal(true))

      dispatch(updateMultipleFeatureList(foundFeatures))
    }
    // if one feature found, set it as a selected feature
    else if (foundFeatures.length === 1) {
      dispatch(updateSelectedFeature(foundFeatures[0]))
    }
  }

  const processWmsClickError = (error) => {
    logError('NetworkError', `error while fetching features, ${error}`)
    dispatch(toggleDataLoading(false))
    // disable previous highlight
    if (highlighted.current) highlighted.current.setVisible(false)
  }

  const resetWMSInteractions = () => {
    const targetWms = selectedProject === 'vd' ? wmsLayerRef : wmsDataLayerRef
    if (!targetWms.current) return
    if (wmsInteractionRef.current) {
      mapRef.current.removeInteraction(wmsInteractionRef.current)
    }
    wmsInteractionRef.current = new WMSClick(
      openFlag,
      targetWms.current.getSource(),
      ORGANIZATION_ID,
      setLoading,
      processWmsClickData,
      processWmsClickError
    )
    mapRef.current.addInteraction(wmsInteractionRef.current)
  }

  const handleSelectedFeature = async () => {
    highlightFeatures(selectedFeature)
    const recordId = selectedFeature?.id.split('.')[1]
    const featProperties = selectedFeature?.properties

    // get formdata from records query in offers project & pk here refer to record i
    const featData =
      selectedProject === 'vd'
        ? JSON.parse(featProperties?.data || '')
        : await getRecordData({
            taskId: selectedListItem.id,
            pk: featProperties.id,
          })

    dispatch(
      updateSelectedRecord({
        id: selectedProject === 'vd' ? recordId : featProperties?.id,
        data:
          selectedProject === 'vd' ? featData : featData?.data[0]?.data?.rased,
        layerId: featProperties?.layer_id,
      })
    )
    dispatch(toggleDataLoading(false))
    dispatch(toggleFormDrawer(true))
  }

  useEffect(() => {
    if (selectedFeature) handleSelectedFeature()
  }, [selectedFeature])

  // fit the map to the corresponding boundaries
  useEffect(() => {
    if (selectedListItem?.boundaries) {
      const { boundaries } = selectedListItem
      if (selectedListItem.type === 'task' && boundaries?.coordinates) {
        const view = mapRef.current.getView()
        const extent = getPolygonExtent(boundaries)
        view.fit(extent, {
          duration: 3000,
          maxZoom: 14,
        })
      }
    }
  }, [selectedListItem])

  useEffect(() => {
    const wmsLayerName = selectedListItem?.wmsFeaturetypeName
    const wmsDataLayerName = selectedListItem?.wmsDataLayerName
    if (!wmsLayerName) return null

    // set the wms source for the baselayer
    const source = createWmsLayer({ wmsLayerName })
    if (wmsLayerRef.current) wmsLayerRef.current.setSource(source)
    else {
      wmsLayerRef.current = new ImageLayer({ source })
      mapRef.current.addLayer(wmsLayerRef.current)
    }

    // set the wms source for the datalayer if found
    if (wmsDataLayerName) {
      const dataLayerSource = createWmsLayer({
        wmsLayerName: wmsDataLayerName,
      })
      if (wmsDataLayerRef.current) {
        wmsDataLayerRef.current.setSource(dataLayerSource)
      } else {
        wmsDataLayerRef.current = new ImageLayer({ source: dataLayerSource })
        mapRef.current.addLayer(wmsDataLayerRef.current)
      }
    }

    resetWMSInteractions()
    if (resetWMSFlag && highlighted.current) {
      highlighted?.current.getSource().clear()
    }
  }, [selectedListItem, resetWMSFlag])

  useEffect(() => {
    // disable feature identify during drawing tools or add a new geometry
    resetWMSInteractions()
  }, [openFlag])

  useEffect(() => {
    if (!selectedRecord?.id && highlighted.current !== null)
      highlighted.current.setVisible(false)
  }, [selectedRecord?.id])

  return (
    <div className={classes.mapWrapper}>
      <BaseMap mapRef={mapRef} />
    </div>
  )
}
