/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Button,
  IconButton,
  CircularProgress,
  FormLabel,
} from '@material-ui/core'
import { useTranslation } from '@flint/locales'

import AddIcon from '@material-ui/icons/Add'
import { WidgetProps } from '@rjsf/utils'
import DeleteIcon from '@material-ui/icons/Delete'
import { SubTitle } from 'Rjsf'
import { acceptableExtensions } from 'shared/constants'
import { processFiles } from 'utils/file.utils'
import { ImagePlaceholder } from 'icons'
import { FullscreenImageDialog } from './FullscreenImageDialog'
import { useStyles } from './AddPhotoToGallery.style'

export const AddPhotoToGallery = (props: WidgetProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const {
    multiple,
    value,
    formContext,
    onChange,
    uiSchema,
    schema,
    id,
    required,
  } = props
  const [loading, setLoading] = useState(false)
  const [fullscreenImage, setFullscreenImage] = useState<string | null>(null)

  const files = value.filter(Boolean)

  const _onChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    try {
      const processedFiles = await processFiles(event.target.files)
      const onFileChange = formContext.onFileChange

      if (onFileChange && processedFiles.length > 0) {
        const response = (await onFileChange(processedFiles)) as Array<string>
        if (multiple) {
          onChange([...files, ...response])
        } else if (Array.isArray(response)) {
          onChange(response[0])
        } else {
          onChange(response)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (files.length !== value.length) onChange([...files])
  }, [files])

  const deleteHandler = (index: number) => {
    let _values: undefined | string[] = [...files]
    if (multiple) {
      _values.splice(index, 1)
    } else {
      _values = undefined
    }
    onChange(_values)
  }

  const getExtension = (str) => str.slice(str.lastIndexOf('.') + 1)

  const title = uiSchema['ui:title'] ?? schema.title
  return (
    <div className={classes.root}>
      {schema.title && (
        <FormLabel className={classes.label} required={required} htmlFor={id}>
          {title}
        </FormLabel>
      )}
      {files.length > 0 && <SubTitle subTitle="الصور" />}

      <div className={classes.uploadedFilesContainer}>
        {files.map((url, fileIndex) => (
          <div key={fileIndex} className={classes.uploadedFilePlaceholder}>
            <div key={fileIndex} className={classes.uploadedFileContainer}>
              <img
                src={
                  acceptableExtensions.includes(getExtension(url))
                    ? url
                    : ImagePlaceholder
                }
                alt="uploadedImage"
                className={classes.uploadedFile}
                onClick={() => setFullscreenImage(url)}
              />
              <IconButton
                className={classes.iconButtomWrapper}
                onClick={() => {
                  deleteHandler(fileIndex)
                }}
              >
                <DeleteIcon color="error" fontSize="small" />
              </IconButton>
            </div>
          </div>
        ))}
      </div>
      <div>
        <input
          type="file"
          id={id}
          onChange={_onChange}
          className={classes.hideInput}
          multiple={multiple}
          defaultValue=""
          // accept="image/*"
          // capture="environment"
        />
        <label htmlFor={loading ? '' : id}>
          <Button
            variant="contained"
            component="span"
            fullWidth
            color="primary"
            size="large"
            className={classes.addphoto}
          >
            {loading ? (
              <CircularProgress size={30} thickness={5} color="secondary" />
            ) : (
              <>
                <AddIcon className={classes.icon} />
                <span>{t('add photos')}</span>
              </>
            )}
          </Button>
        </label>
      </div>
      <FullscreenImageDialog
        open={!!fullscreenImage}
        onClose={() => setFullscreenImage(null)}
        imageUrl={fullscreenImage}
      />
    </div>
  )
}
