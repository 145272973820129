import { useDispatch, useSelector } from 'react-redux'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import { toggleLayersDrawerOpen, updateSelectedListItem } from 'store/layout'
import { isSimpleProject } from 'utils'
import { RootState } from 'store'
import { formatListItem } from 'utils/tasks.utils'
import { useStyle } from './CustomListItem.style'

export const ListItemContent = ({
  item,
  title,
}: {
  item: any
  title: 'layer' | 'task'
}) => {
  const classes = useStyle()

  const dispatch = useDispatch()
  const selectedListItem = useSelector(
    (state: RootState) => state.layout.selectedListItem
  )
  const isActiveListItem = item?.id === selectedListItem?.id

  const handleClick = () => {
    dispatch(toggleLayersDrawerOpen(false))
    dispatch(
      updateSelectedListItem(
        formatListItem(item, title, isSimpleProject ? 'simple' : undefined)
      )
    )
  }

  return (
    <ListItemText
      style={{ marginTop: 0, marginRight: '8px' }}
      onClick={handleClick}
    >
      <Typography
        className={`${
          isActiveListItem ? classes.activeTaskTitle : classes.taskTitle
        }`}
      >
        {item?.title || item?.name || item?.code}
      </Typography>
      <Typography className={classes.teamCode}>{item?.teamCode}</Typography>
    </ListItemText>
  )
}
